import { template as template_e893d7993ef2466b8bba754db6f43fe1 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e893d7993ef2466b8bba754db6f43fe1(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
