import { template as template_9fc4d19accbb41a2beeab215d71002e2 } from "@ember/template-compiler";
const FKText = template_9fc4d19accbb41a2beeab215d71002e2(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
